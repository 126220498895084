// Required for meta.load-css below
@use "sass:meta";

@layer corteva-research, primeng, corteva-research-fonts, manager;

@layer corteva-research {
  @import "@corteva-research/ngx-components-core/assets/styles/research-web-components.scss";
}

// PrimeNG specifies its own layers. Because its styles are plain CSS,
// importing with `@import` will cause SASS to process it first and give it the
// lowest precedence, so it must be imported with `@include meta.load-css`
@include meta.load-css("primeng/resources/themes/saga-blue/theme.css");
@include meta.load-css("primeng/resources/primeng.min.css");

// @layer bootstrap {
//   @import "@corteva-research/ngx-components-core/assets/styles/research-web-components.bootstrap.scss";
// }

@layer corteva-research-fonts {
  @import "@corteva-research/ngx-components-core/assets/fonts/fonts.scss";
}

@layer manager {
  .height .ui-widget-content {
    height: 100%;
  }
  
  .dropdown-menu .link-text {
    font-size: small;
  }
  
  .ng-valid[required],
  .ng-valid.required {
    border-bottom: 5px thin #42A948;
    /* green */
  }
  
  .ng-invalid:not(form) {
    border-bottom: 3px solid #a94442;
    /* red */
  }
  
  p-multiselect.ng-invalid:not(form) > div {
    border-bottom: 3px solid #a94442 !important;
    /* red */
  }
  
  p-dropdown.ng-invalid:not(form) > div {
    border-bottom: 3px solid #a94442 !important;
    /* red */
  }
  
  .ui-dropdown {
    vertical-align: bottom;
  }
  
  .ui-multiselect {
    vertical-align: bottom;
  }
  
  .ui-datepicker table {
    width: 100% !important;
  }
  
  .ui-datepicker.ui-widget {
    width: auto;
  }
  
  .ui-dropdown-filter-container {
    width: 100%;
  }
  
  .ui-multiselect .ui-multiselect-label {
    font-weight: 100;
  }
  
  .submitButtonColor [icon="fa fa-upload"] {
    background-color: #5cb85c;
    border-color: #5cb85c;
    float: right;
  }
  
  .submitButtonColor [icon="fa fa-close"] {
    background-color: #f0ad4e;
    border-color: #f0ad4e;
  }
  
  .ui-fluid .ui-fileupload .ui-button{
    width: 13%;
  }
  
  .ui-tooltip{
    width: 400px;
    height: auto;
  }
  
  
  .navbar-nav.nav>.res-nav-item-container:nth-child(2)>li .link-icon {
    background-color: #7d6bb8;
    color: #b7b6dc;
  }
  
  .ui-datatable .ui-datatable-loading-content {
    top: 250%;
  }
  
  .margin .ui-panel .ui-panel-content {
    padding: .0em .0em;
  }
  
  .titleHide .ui-panel .ui-panel-titlebar {
    padding: .1em .75em;
  }
  
  .ui-table .ui-table-thead>tr>th,
  .ui-table .ui-table-tbody>tr>td,
  .ui-table .ui-table-tfoot>tr>td {
    padding: .0em .0em !important;
  }
  
   #dtCalendar .ui-table table {
    border-left: 1px solid #D5D5D5 !important;
    border-right: 1px solid #D5D5D5 !important;
    border-bottom: 1px solid #D5D5D5 !important;
  }
   .demandCalendarTable .ui-table .ui-table-tbody>tr>td {
    border: none !important;
  }
  
  .ui-datatable .ui-datatable-thead>tr>th,
  .ui-datatable .ui-datatable-tfoot>tr>td,
  .ui-datatable .ui-datatable-data>tr>td {
    padding: .0em .0em !important;
  }
  
  .ui-datatable .ui-datatable-frozen-view .ui-datatable-data>tr>td,
  .ui-datatable .ui-datatable-unfrozen-view .ui-datatable-data>tr>td {
    height: 24px;
  }
  
  .p-button-success:hover {
    background-color: #00965E !important;
    border-color:#00965E !important;
    color: white !important;
  }
  
  .p-button-danger:hover {
    background-color: #e91224 !important;
    border-color: #e91224 !important;
    color: white !important;
  }
  
  .p-button-warning:hover {
    background-color: #ffba01 !important;
    border-color: #ffba01 !important;
    color: white !important;
  }
  
  
  div.logo-text {
    text-transform: none !important;
    font-weight: 700;
    font-size: 40px !important;
  }
  
  .badge {
    background-color: #777;
  }
  
  .p-component, .p-link, .p-inputtext{
    font-size: 14px !important;
  }
  
  .p-datatable>.p-datatable-wrapper {
    overflow: initial !important;
  }
  
  .align-center {
    text-align: center;
  }
  
  .btn {
    width: 100%;
  }
  
  .p-inputtext {
    width: 100%;
  }
  
  .colordiv {
    margin: 1% 0 0 1%;
  }
  
  .colorbox {
    border-radius: 5px;
    width: 20px;
    height: 20px;
    display: inline-block;
    box-sizing: border-box;
    margin: 0 1% 1% 0;
    border: 1px solid black;
  }
  
  .fixed .ui-datatable-scrollable-view{
    float: none!important;
  }
  
  .partitionBorder{
    border: solid thin;
  
  }
  
  .rightAlign{
    float: right;
  }
  
  .controlLabel{
    text-align: right;
  }
  
  .ui-datatable .ui-datatable-thead>tr>th, .ui-datatable .ui-datatable-tfoot>tr>td, .ui-datatable .ui-datatable-data>tr>td{
    padding: 0 !important;
  }
  
  .panelheight{
    height: 100%;
  }
  
  .height .ui-widget-content {
    height: 100% ;
  }
  
  .programGridHeight .ui-datatable .ui-datatable-scrollable-body{
    height: 100%;
    border: 1px solid #D5D5D5;
  }
  
  .disabledOpacityChange.ui-state-disabled, .ui-widget:disabled{
    opacity: 0.5;
    font-weight: bold;
  }
  
  .disabled .ui-state-disabled, .ui-widget:disabled{
    opacity: 0.6;
    font-weight: bold;
  }
  
  .ui-grid.ui-grid-pad>.ui-grid-row>div{
    padding: .25em .5em;
  }
  
  .long-width.ui-multiselect-panel {
    min-width: 29%;
  }
  
  .removeSpacing {
    padding: .0em .5em;
  }
  
  .marginLeft{
  margin-left: -6px;
  }
  
  .width100 {
  width: 100%;
  }
  
  /* datatable padding */
  .p-datatable .p-datatable-header,
  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr > td,
  .p-datatable .p-datatable-tfoot > tr > td {
    padding: 0.5rem 0.5rem !important;
  }
  
  /* dialog box */
  .p-dialog .p-dialog-header{
    padding: 1rem !important;
    background: #f8f9fa !important;
  }
  
  .p-dialog .p-dialog-content {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  
  /* tabs */
  .p-tabview .p-tabview-nav li {
    border: 1px solid #dee2e6;
  }
  
  .p-tabview .p-tabview-panels {
    padding: 0 !important;
  }
  
  p {
    margin-bottom: 0.5rem !important;
  }
  
  .currentEnv div {
    font-size: 13px !important;
    color:red !important;
  }
  
  .p-datatable-resizable-table>.p-datatable-thead>tr>th,
  .p-datatable-resizable-table>.p-datatable-tfoot>tr>td,
  .p-datatable-resizable-table>.p-datatable-tbody>tr>td {
    white-space: normal !important;
  }
  
  .p-panel-content [class=col-3], .p-panel-content [class=col-9], .removeSpacing {
    padding: 2px !important;
  }
  
  p-dialog [class=col-5], p-dialog [class=col-7], .dropDowmSpacing {
    padding: 2px !important;
  }
  
  p-dialog [class=col-2] {
    padding: 0.5rem 0 0 0 !important;
  }
  
  .p-panel .p-panel-footer button {
    width: 100%;
  }
  
  table {
    margin-bottom: 0px;
  }
  
  .p-panel .p-panel-content{
    padding: 1rem 1rem 0 1rem !important;
  }
  
  .p-panel .p-panel-footer {
    padding: 0.5rem 0.5rem 0 0.5rem !important;
  }
  
  .p-toast-top-right {
    top: 80px !important;
  }

  :has(> p-table) {
    overflow: auto;
  }

  //html, body {
  //  height: 100%;
  //  margin: 0;
  //}
   
  .table-container {
    display: flex;
    flex-direction: column;
    height: 80vh; /* Full viewport height */
  }
   
  //.p-datatable-scrollable-body {
  //  flex: 1 1 auto;
  //}
}